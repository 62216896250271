import React from "react";
import "./WishList.css";
import Banner_Card from "../Common_Component/Banner_Card/Banner_Card";
import Product_Details_Div from "../Common_Component/Product_Details_Div/Product_Details_Div";
import Color_Cart_Holder from "../Common_Component/Color_Cart_Holder/Color_Cart_Holder";
import Heart_Component from "../Common_Component/Heart_Component/Heart_Component";
import BreadcrumComponent from "../Common_Component/BreadcrumComponent/BreadcrumComponent";
import Pagenation from "../Common_Component/Pagenation/Pagenation";
import { Link } from "react-router-dom";
import Buttons from "../Common_Component/Buttons/Buttons";

function WishList() {
  const sliderMenImages = [
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
      detailsLink: "/product-cart-inner",
      subheading: "Peter England -",
      spanText: " Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
      detailsLink: "/product-cart-inner",
      subheading: "Allen Solly -",
      spanText: "Semi Formal Shirt",
      productDescription: "Men Blue Solid Casual Jacket",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
      detailsLink: "/product-cart-inner",
      subheading: "Van Heusen - ",
      spanText: "Semi Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Formal Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },

    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
      detailsLink: "/product-cart-inner",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Shirt",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
      detailsLink: "/product-cart-inner",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Full Sleeves Formal Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
      detailsLink: "/product-cart-inner",
      subheading: "Peter England -",
      spanText: " Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
      detailsLink: "/product-cart-inner",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Shirt",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
      detailsLink: "/product-cart-inner",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Full Sleeves Formal Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
      detailsLink: "/product-cart-inner",
      subheading: "Peter England -",
      spanText: " Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
      detailsLink: "/product-cart-inner",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Shirt",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
      detailsLink: "/product-cart-inner",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Full Sleeves Formal Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
      detailsLink: "/product-cart-inner",
      subheading: "Peter England -",
      spanText: " Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
  ];

  const breadcrumbItems = [
    { text: "Home", link: "/" },
    { text: "Wishlist", className: "active" },
  ];
  return (
    <>
      <div className="WishList">
        <div className="container">
          <div className="mb-4">
            <BreadcrumComponent items={breadcrumbItems} />
          </div>
          <div className="WishList_Card_Holder">
            <div className="row">
              {sliderMenImages.map((product, index) => (
                <div
                  key={index}
                  className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-6 col-6"
                >
                  <Banner_Card
                    imgClassName="Tab_Slider_Img"
                    detailsLink={product.detailsLink}
                    imgSource={product.imgSource}
                    heartClassName={"Heart_Like"}
                    HeartLikeComponent={<Heart_Component />}
                    ProductDetailsClassName={"Product_Details"}
                    ProductDetailsSection={
                      <Product_Details_Div
                        ColorCartHolder={<Color_Cart_Holder />}
                        subheading={product.subheading}
                        spanText={product.spanText}
                        productDescription={product.productDescription}
                        Price={product.Price}
                        subPrice={product.subPrice}
                      />
                    }
                  />
                  
                </div>
              ))}
            </div>
          </div>
          <div className="add_all_btn">
            <Link to={"/product-cart-inner"}>
              <Buttons className="SubmitBtn" text="Add all to cart" />
            </Link>
          </div>

          <Pagenation />
        </div>
      </div>
    </>
  );
}

export default WishList;
