import React from 'react'
import './WhyChoose.css'
const WhyChoose = () => {
    const features = [
        {
            icon: "/assets/about/free-shipping.png",
            title: "Free Shipping",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr."
        },
        {
            icon: "/assets/about/coin.png",
            title: "100% Money Back Guarantee",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr."
        },
        {
            icon: "/assets/about/headphones.png",
            title: "Online Support 24/7",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industr."
        }
    ];
    return (
        <>
            <section className="why-choose">
                <div className="text-center">
                    <h3 className="hot-title">Why Choose Us</h3>
                    <div className="title-border"></div>
                </div>
                <div className="container mt-5">
                    <div className="row">
                        {features.map((feature, index) => (
                            <div key={index} className={"col-lg-4 bd-right"}>
                                <div className="d-flex choos-col">
                                    <div className="choose-fafa-bg text-center">
                                        <img src={process.env.PUBLIC_URL + feature.icon} className="choose-fafa-icons" alt={feature.title} />
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="title">{feature.title}</h6>
                                        <p className="sub-text">{feature.text}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default WhyChoose