import React from "react";
import "./Crazy_Deals.css";
import Heading_Holder from "../../Common_Component/Heading_Holder/Heading_Holder";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

function Crazy_Deals() {
  // Array containing image sources
  // const imageSources = [
  //   "/assets/Home/Crazy_Deals/Crazy_Deals_Img_1.png",
  //   "/assets/Home/Crazy_Deals/Crazy_Deals_Img_2.png",
  //   "/assets/Home/Crazy_Deals/Crazy_Deals_Img_3.png",
  //   "/assets/Home/Crazy_Deals/Crazy_Deals_Img_1.png",
  //   "/assets/Home/Crazy_Deals/Crazy_Deals_Img_2.png",
  //   "/assets/Home/Crazy_Deals/Crazy_Deals_Img_3.png",
  // ];
  const imageSources = [
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/Crazy_Deals/Crazy_Deals_Img_1.png",

      detailsLink: "/our_products_page",
    },

    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/Crazy_Deals/Crazy_Deals_Img_2.png",

      detailsLink: "/our_products_page",
    },

    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/Crazy_Deals/Crazy_Deals_Img_3.png",

      detailsLink: "/our_products_page",
    },

    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/Crazy_Deals/Crazy_Deals_Img_1.png",

      detailsLink: "/our_products_page",
    },

    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/Crazy_Deals/Crazy_Deals_Img_2.png",

      detailsLink: "/our_products_page",
    },

    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/Crazy_Deals/Crazy_Deals_Img_3.png",

      detailsLink: "/our_products_page",
    },
  ];

  return (
    <>
      <div className="Crazy_Deals">
        <div className="container">
          <Heading_Holder headingText={"CRAZY DEALS"} />

          <div className="Crazy_Deals_Swiper_holder">
            <Swiper
              className="mySwiper CrazyDealsSwiper"
              spaceBetween={20}
              slidesPerView={3}
              pagination={{ clickable: true }}
              modules={[Pagination]}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                },
                380: {
                  slidesPerView: 2,
                },
                485: {
                  slidesPerView: 2,
                },
                575: {
                  slidesPerView: 2,
                },

                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 3,
                },
                1440: {
                  slidesPerView: 3,
                },
                2000: {
                  slidesPerView: 3,
                },
              }}
            >
              {imageSources.map((slide, index) => (
                <SwiperSlide key={index}>
                  <div className="crazybanmain">
                    <Banner_Card
                      className="Crazy_Deals_Swiper_Img_holder"
                      imgClassName="Crazy_Deals_Swiper_Img"
                      imgSource={slide.imgSource}
                      detailsLink={slide.detailsLink}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Crazy_Deals;
