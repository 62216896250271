import React, { useRef, useState } from "react";
import "./Explore_Brands.css";
import Heading_Holder from "../../Common_Component/Heading_Holder/Heading_Holder";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import required modules
import { Grid, Pagination } from "swiper/modules";

function Explore_Brands() {
  // Define an array containing image sources
  const imageSources = [
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_1.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_2.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_3.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_4.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_5.png",

    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_1.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_2.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_3.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_4.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_5.png",

    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_1.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_2.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_3.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_4.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_5.png",
  ];

  return (
    <>
      <div className="Explore_Brands">
        <div className="container">
          <Heading_Holder headingText="EXPLORE BRANDS" />

          <div className="Explore_Brands_Slider_Holder">
            <Swiper
              slidesPerView={6}
              grid={{
                rows: 2,
              }}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              modules={[Grid, Pagination]}
              className="mySwiper Explore_Brands_Slider"

              breakpoints={{
                    0: {
                      slidesPerView: 2,
                      spaceBetween: 5,
                    },
                    380: {
                      slidesPerView: 3,
                      spaceBetween: 5,
                    },
                    485: {
                      slidesPerView: 4,
                      spaceBetween: 5,
                    },
                    575: {
                      slidesPerView: 4,
                      spaceBetween: 5,
                    },

                    768: {
                      slidesPerView: 4,
                      spaceBetween: 5,
                    },
                    992: {
                      slidesPerView: 6,
                      spaceBetween: 5,
                    },
                    1024: {
                      slidesPerView: 5,
                      spaceBetween: 5,
                    },
                    1200: {
                      slidesPerView: 5,
                      spaceBetween: 5,
                    },
                    1440: {
                      slidesPerView: 5,
                      spaceBetween: 10,
                    },
                    2000: {
                      slidesPerView: 4,
                      spaceBetween: 20,
                    },
                  }}
            >
              {imageSources.map((imageSource, index) => (
                <SwiperSlide key={index}>
                  <Banner_Card
                      className="Explore_Brands_Slider_Img_holder"
                    imgClassName="Explore_Brands_Slider_Img"
                    imgSource={process.env.PUBLIC_URL + imageSource}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Explore_Brands;
