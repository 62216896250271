import React from "react";
import Heading_Holder from "../../Common_Component/Heading_Holder/Heading_Holder";
import "./New_Arrivels.css";
import Tab from "react-bootstrap/Tab";
import Nav_Pills_Holder from "../../Common_Component/Nav_Pills_Holder/Nav_Pills_Holder";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";

import "swiper/css";
import "swiper/css/pagination";
import Product_Swiper from "../../Common_Component/Product_Swiper/Product_Swiper";

function New_Arrivels() {
  const ShopTrendsPiils = [
    { eventKey: "Men", title: "Men" },
    { eventKey: "Women", title: "Women" },
    { eventKey: "Kids", title: "Kids" },
  ];

  const sliderMenImages = [
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
      detailsLink: "./productdescription",
      subheading: "Peter England -",
      spanText: " Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
      subheading: "Allen Solly -",
      spanText: "Semi Formal Shirt",
      productDescription: "Men Blue Solid Casual Jacket",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
      subheading: "Van Heusen - ",
      spanText: "Semi Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Formal Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },

    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Shirt",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Full Sleeves Formal Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
      subheading: "Peter England -",
      spanText: " Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
  ];

  const sliderWomenImages = [
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
      detailsLink: "./productdescription",
      subheading: "Peter England -",
      spanText: " Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
      subheading: "Allen Solly -",
      spanText: "Semi Formal Shirt",
      productDescription: "Men Blue Solid Casual Jacket",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
      subheading: "Van Heusen - ",
      spanText: "Semi Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Formal Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },

    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Shirt",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Full Sleeves Formal Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
      subheading: "Peter England -",
      spanText: " Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
  ];

  const sliderKidsImages = [
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
      detailsLink: "./productdescription",
      subheading: "Peter England -",
      spanText: " Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
      subheading: "Allen Solly -",
      spanText: "Semi Formal Shirt",
      productDescription: "Men Blue Solid Casual Jacket",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
      subheading: "Van Heusen - ",
      spanText: "Semi Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Formal Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },

    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Shirt",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
      subheading: "Raymond -",
      spanText: " Formal Shirt",
      productDescription: "Men Slim Fit Full Sleeves Formal Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
      subheading: "Peter England -",
      spanText: " Formal Shirt",
      productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
      Price: "₹ 1,234.00",
      subPrice: "₹ 1,234.00",
    },
  ];

  return (
    <>
      <div className="Shop_Trends New_Arrivels">
        <div className="container">
          <Heading_Holder headingText={"NEW ARRIVALS"} />

          <div className="Shop_Trends_Cards">
            <Tab.Container id="left-tabs-example" defaultActiveKey="Men">
              <div className="row">
                <div className="col-md-12">
                  <Nav_Pills_Holder tabs={ShopTrendsPiils} />
                </div>
                <div className="tab_content_holder">
                  <Tab.Content>
                    <Tab.Pane eventKey="Men">
                      <div className="Men_Tab_Content">
                        <div className="row">
                          <div className="col-lg-4 col-md-5">
                            <div className="Men_Tab_Content_img_holder main_img_holder">
                              <Banner_Card
                                imgClassName="Women_Tab_Content_img"
                                imgSource={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/New_Arrivel/New_Arrivel_Main_Img.png"
                                }
                                detailsLink="/our_products_page"
                                overlayClassName={"overlay"}
                                overlayText={"New Arrivals"}
                                overlaySubText={"View All"}
                                background={"#2A2A2A"}
                                padding={"9px 0px"}
                              />
                            </div>
                          </div>

                          <div className="col-lg-8  col-md-7">
                            <div className="Men_Tab_Content_img_holder">
                              <Product_Swiper
                                sliderImages={sliderMenImages}
                                slidesPerView={3}
                                breakpoints={{
                                  0: { slidesPerView: 2 },
                                  380: { slidesPerView: 2 },
                                  485: { slidesPerView: 2 },
                                  575: { slidesPerView: 2 },
                                  768: { slidesPerView: 2 },
                                  992: { slidesPerView: 3 },
                                  1024: { slidesPerView: 3 },
                                  1200: { slidesPerView: 3 },
                                  1440: { slidesPerView: 3 },
                                  2000: { slidesPerView: 3 },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="Women">
                      <div className="Women_Tab_Content">
                        <div className="Men_Tab_Content">
                          <div className="row">
                            <div className="col-lg-4 col-md-5">
                              <div className="Men_Tab_Content_img_holder">
                                <Banner_Card
                                  imgClassName="Women_Tab_Content_img"
                                  imgSource={
                                    process.env.PUBLIC_URL +
                                    "/assets/Home/New_Arrivel/New_Arrivel_Main_Img.png"
                                  }
                                  overlayClassName={"overlay"}
                                  overlayText={"New Arrivals"}
                                  overlaySubText={"View All"}
                                  background={"#2A2A2A"}
                                  padding={"9px 0px"}
                                />
                              </div>
                            </div>

                            <div className="col-lg-8  col-md-7">
                              <div className="Men_Tab_Content_img_holder main_img_holder">
                                <Product_Swiper
                                  sliderImages={sliderWomenImages}
                                  slidesPerView={3}
                                  breakpoints={{
                                    0: { slidesPerView: 2 },
                                    380: { slidesPerView: 2 },
                                    485: { slidesPerView: 2 },
                                    575: { slidesPerView: 2 },
                                    768: { slidesPerView: 2 },
                                    992: { slidesPerView: 3 },
                                    1024: { slidesPerView: 3 },
                                    1200: { slidesPerView: 3 },
                                    1440: { slidesPerView: 3 },
                                    2000: { slidesPerView: 3 },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="Kids">
                      <div className="Women_Tab_Content">
                        <div className="Men_Tab_Content">
                          <div className="row">
                            <div className="col-lg-4 col-md-5">
                              <div className="Men_Tab_Content_img_holder">
                                <Banner_Card
                                  imgClassName="Women_Tab_Content_img"
                                  imgSource={
                                    process.env.PUBLIC_URL +
                                    "/assets/Home/New_Arrivel/New_Arrivel_Main_Img.png"
                                  }
                                  overlayClassName={"overlay"}
                                  overlayText={"New Arrivals"}
                                  overlaySubText={"View All"}
                                  background={"#2A2A2A"}
                                  padding={"9px 0px"}
                                />
                              </div>
                            </div>

                            <div className="col-lg-8  col-md-7">
                              <div className="Men_Tab_Content_img_holder main_img_holder">
                                <Product_Swiper
                                  sliderImages={sliderKidsImages}
                                  slidesPerView={3}
                                  breakpoints={{
                                    0: { slidesPerView: 2 },
                                    380: { slidesPerView: 2 },
                                    485: { slidesPerView: 2 },
                                    575: { slidesPerView: 2 },
                                    768: { slidesPerView: 2 },
                                    992: { slidesPerView: 3 },
                                    1024: { slidesPerView: 3 },
                                    1200: { slidesPerView: 3 },
                                    1440: { slidesPerView: 3 },
                                    2000: { slidesPerView: 3 },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default New_Arrivels;
