import { React, useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./Category.css";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";

// SwiperCore.use([Pagination]);

const Category = () => {
  const [swiperInstancenew, setSwiperInstancenew] = useState(null);
  const swiperRef = useRef(null);
  // const handleMainSliderClick = (direction) => {
  //   if (swiperInstancenew) {
  //     if (direction === "prev") {
  //       swiperInstancenew.slidePrev();
  //     } else if (direction === "next") {
  //       swiperInstancenew.slideNext();
  //     }
  //   }
  // };

  const handleSliderClick = (direction) => {
    if (swiperInstancenew) {
      if (direction === "prev") {
        swiperInstancenew.slidePrev();
      } else if (direction === "next") {
        swiperInstancenew.slideNext();
      }
    }
  };

    const images = [
      {
        imgSource:
          process.env.PUBLIC_URL +
          "/assets/Home/Select_By_Catagory/Men/Men_Img_1.png",
      
        detailsLink:"/our_products_page",
      },

      {
        imgSource:
          process.env.PUBLIC_URL +
          "/assets/Home/Select_By_Catagory/Men/Men_Img_2.png",
      
        detailsLink:"/our_products_page",
      },

      {
        imgSource:
          process.env.PUBLIC_URL +
          "/assets/Home/Select_By_Catagory/Men/Men_Img_3.png",
      
        detailsLink:"/our_products_page",
      },

      {
        imgSource:
          process.env.PUBLIC_URL +
          "/assets/Home/Select_By_Catagory/Men/Men_Img_4.png",
      
        detailsLink:"/our_products_page",
      },

      {
        imgSource:
          process.env.PUBLIC_URL +
          "/assets/Home/Select_By_Catagory/Men/Men_Img_5.png",
      
        detailsLink:"/our_products_page",
      },

      {
        imgSource:
          process.env.PUBLIC_URL +
          "/assets/Home/Select_By_Catagory/Men/Men_Img_6.png",
      
        detailsLink:"/our_products_page",
      },

      {
        imgSource:
          process.env.PUBLIC_URL +
          "/assets/Home/Select_By_Catagory/Men/Men_Img_1.png",
      
        detailsLink:"/our_products_page",
      },

      {
        imgSource:
          process.env.PUBLIC_URL +
          "/assets/Home/Select_By_Catagory/Men/Men_Img_2.png",
      
        detailsLink:"/our_products_page",
      },

      {
        imgSource:
          process.env.PUBLIC_URL +
          "/assets/Home/Select_By_Catagory/Men/Men_Img_3.png",
      
        detailsLink:"/our_products_page",
      },

      {
        imgSource:
          process.env.PUBLIC_URL +
          "/assets/Home/Select_By_Catagory/Men/Men_Img_4.png",
      
        detailsLink:"/our_products_page",
      },

      {
        imgSource:
          process.env.PUBLIC_URL +
          "/assets/Home/Select_By_Catagory/Men/Men_Img_5.png",
      
        detailsLink:"/our_products_page",
      },

      {
        imgSource:
          process.env.PUBLIC_URL +
          "/assets/Home/Select_By_Catagory/Men/Men_Img_6.png",
      
        detailsLink:"/our_products_page",
      },
  ];

  const renderSwiperSlides = () => {
    return images.map((image, index) => (
      <SwiperSlide key={index}>
        <div>
          <div className="imgHovermain">
            <Banner_Card
              imgSource={process.env.PUBLIC_URL + image}
              imgClassName="swiperImg"
            />
          </div>
          <div className="text-center cardDEtails">
            <h3>Men's T-shirt</h3>
            <p>Explore</p>
          </div>
        </div>
      </SwiperSlide>
    ));
  };

  const renderCategoryTabs = () => {
    const categories = ["Men", "Women", "Kids", "More..."];

    return categories.map((category, index) => (
      <Nav.Item key={index}>
        <Nav.Link eventKey={index + 1}>{category}</Nav.Link>
      </Nav.Item>
    ));
  };

  return (
    <section className="Category ">
      <div className="container ">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                <Row>
                  <Col md={3} lg={2}>
                    <div className="shopCategoryhead">
                      <h2 className="heading">Shop By Category</h2>
                    </div>
                    <Nav variant="pills" className="categoryTabs">
                      {renderCategoryTabs()}
                    </Nav>
                  </Col>
                  <Col md={9} lg={10}>
                    <Tab.Content>
                      {[1, 2, 3, 4].map((categoryKey) => (
                        <Tab.Pane
                          key={categoryKey}
                          eventKey={String(categoryKey)}
                        >
                          <div className="background-product-detail ">
                            <div className="product-detail">
                              <Swiper
                                slidesPerView={3}
                                spaceBetween={30}
                                navigation={true}
                                pagination={{ clickable: true }}
                                modules={[Navigation, Pagination]}
                                className="mySwiper swiperCategory"
                                ref={swiperRef}
                                onSwiper={(swiper) =>
                                  setSwiperInstancenew(swiper)
                                }
                                breakpoints={{
                                  280: {
                                    slidesPerView: 2,
                                  },
                                  320: {
                                    slidesPerView: 2,
                                  },

                                  420: {
                                    slidesPerView: 3,
                                  },
                                  768: {
                                    slidesPerView: 3,
                                  },
                                  991: {
                                    slidesPerView: 4,
                                  },
                                  1200: {
                                    slidesPerView: 5,
                                  },
                                }}
                              >
                                {/* {renderSwiperSlides(categoryKey)} */}
                                {images.map((slide, index) => (
                                  <SwiperSlide key={index}>
                                    <div>
                                      <div className="imgHovermain">
                                        <Banner_Card
                                         imgSource={slide.imgSource}
                                          imgClassName="swiperImg"
                                          detailsLink={slide.detailsLink}
                                        />
                                      </div>
                                      <div className="text-center cardDEtails">
                                        <h3>Men's T-shirt</h3>
                                        <p>Explore</p>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </div>
                          </div>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Category;
