import React from "react";
import "./Product_Swiper.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Banner_Card from "../Banner_Card/Banner_Card";
import Heart_Component from "../Heart_Component/Heart_Component";
import Product_Details_Div from "../Product_Details_Div/Product_Details_Div";
import Color_Cart_Holder from "../Color_Cart_Holder/Color_Cart_Holder";

function Product_Swiper({ sliderImages, breakpoints, slidesPerView }) {
  return (
    <>
      <div className="Product_Swiper ">
        <Swiper
          className="mySwiper menTabSwiper"
          spaceBetween={15}
          slidesPerView={slidesPerView}
          // pagination={{ clickable: true }}
          // modules={[Pagination]}
          breakpoints={breakpoints}
        >
          {sliderImages.map((slide, index) => (
            <SwiperSlide key={index}>
              <Banner_Card
                imgClassName="Tab_Slider_Img"
                detailsLink={slide.detailsLink}
                imgSource={slide.imgSource}
                heartClassName={"Heart_Like"}
                HeartLikeComponent={<Heart_Component />}
                ProductDetailsClassName={"Product_Details"}
                ProductDetailsSection={
                  <Product_Details_Div
                  ColorCartHolder={<Color_Cart_Holder/>}
                    subheading={slide.subheading}
                    spanText={slide.spanText}
                    productDescription={slide.productDescription}
                    Price={slide.Price}
                    subPrice={slide.subPrice}
                  />
                }
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
}

export default Product_Swiper;
