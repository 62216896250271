import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "react-bootstrap";

import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faMapMarkerAlt, faPhone } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <>
      <section className="footer-section">
        <Container fluid>
          <Container>
            <div className=" footermains pt-4 pb-3">
              <Row>
                <Col lg={4} md={6} sm={6}>
                  <div className="footer-col">
                    <div className="toptext  mb-lg-5">
                      <Link to={"/"}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Websitelogo/netpurti_logo.png"
                          }
                          className="footer-logos"
                          alt="..."
                        />
                      </Link>
                    </div>
                    <div className="footerliks">
                      <Link to={"/"}>
                        <p className="linktxt">HOME</p>
                      </Link>
                      <Link to={"/about-us"}>
                        <p className="linktxt">ABOUT US</p>
                      </Link>
                      <Link to={"/contact-us"}>
                        <p className="linktxt">CONTACT US</p>
                      </Link>
                      <Link to={"/blog"}>
                        <p className="linktxt">BLOGS</p>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={6} sm={6}>
                  <div className="footer-col ">
                    <div className="toptext  mb-lg-5">
                      <h4 className="follows">netpurtisupport@gmail.com</h4>
                    </div>
                    <div className="footerliks">
                      <Link to={""}>
                        <p className="linktxt">PRIVACY POLICY</p>
                      </Link>
                      <Link to={""}>
                        <p className="linktxt">TERMS OF USE</p>
                      </Link>
                      <Link to={""}>
                        <p className="linktxt">RETURN POLICY</p>
                      </Link>
                      <Link to={"/faq"}>
                        <p className="linktxt">FAQs</p>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={6} sm={6}>
                  <div className="footer-col">
                    <div className="toptext   mb-lg-5">
                      <h4 className="follows follow_link">follow us on</h4>
                      <Link to={"https://www.facebook.com/"} target="blank">
                        <div className="footericnss">
                          <FontAwesomeIcon icon={faFacebookF} />
                        </div>
                      </Link>

                      <Link to={"https://in.linkedin.com/"} target="blank">
                        {" "}
                        <div className="footericnss">
                          <FontAwesomeIcon icon={faLinkedinIn} />
                        </div>
                      </Link>
                      <Link to={"https://twitter.com/"} target="blank">
                        <div className="footericnss">
                          <FontAwesomeIcon icon={faTwitter} />
                        </div>
                      </Link>
                      <Link to={"https://www.youtube.com/"} target="blank">
                        <div className="footericnss">
                          <FontAwesomeIcon icon={faYoutube} />
                        </div>
                      </Link>
                    </div>
                    <div className="footerliks">
                      <div className="addresdetails">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="addresicns"
                        />
                        <p className="linktxt ">
                          Akshya Nagar 1st Block, 1st Cross, Patil Nagar, Pune -
                          411038
                        </p>
                      </div>
                      <div className="addresdetails ">
                        <FontAwesomeIcon
                          icon={faPhone}
                          className="addresicns"
                        />
                        <p className="linktxt ">+91 9876543210</p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="bottomtext">
              <div className="botright d-flex align-items-center">
                <FontAwesomeIcon icon={faCircleCheck} className="checkss" />
                <p className="copyright">
                  VERIFIED BY <span className="coprightspan"> NETPURTI</span>
                </p>
              </div>
              <p className="copyright">
                Copyright ©2024 All Rights Reserved. Developed by{" "}
                <Link>
                  <span>Profcyma.</span>
                </Link>
              </p>
            </div>
          </Container>
        </Container>
      </section>
    </>
  );
}

export default Footer;
