import React from "react";
import "./Select_Drop.css";
import Form from "react-bootstrap/Form";

function Select_Drop({ labelText, optionsSelect }) {
  return (
    <>
      <div className="Select_Drop">
        <div className="label_holder">
          <p className="label_text">{labelText}</p>
        </div>
        <div className="option_select">
          <Form.Select aria-label="Default select example">
            {optionsSelect.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
    </>
  );
}

export default Select_Drop;
