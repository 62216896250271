import { React, useState, useRef } from "react";
// import "./B2bSellerDetail.css";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "./ProductCart.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCartShopping,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import Heart_Component from "../../Common_Component/Heart_Component/Heart_Component";
import { Link } from "react-router-dom";
import BreadcrumComponent from "../../Common_Component/BreadcrumComponent/BreadcrumComponent";

const ProductCart = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const swiperRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [swiperInstancemobile, setSwiperInstancemobile] = useState(null);
  const [swiperInstancenew, setSwiperInstancenew] = useState(null);
  const [showAddedToCart, setShowAddedToCart] = useState(false);

  const handleAddToCart = () => {
    setShowAddedToCart(true);
    setTimeout(() => {
      setShowAddedToCart(false);
    }, 3000); // 3 seconds
  };

  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleSliderClick = (direction) => {
    if (swiperInstancenew) {
      if (direction === "prev") {
        swiperInstancenew.slidePrev();
      } else if (direction === "next") {
        swiperInstancenew.slideNext();
      }
    }
  };

  const handleSliderClickMobile = (direction) => {
    if (swiperInstancemobile) {
      if (direction === "prev") {
        swiperInstancemobile.slidePrev();
      } else if (direction === "next") {
        swiperInstancemobile.slideNext();
      }
    }
  };

  const imageUrls = [
    {
      image: process.env.PUBLIC_URL + "/assets/Product Description/pd-1.png",
      baseLink: "/product-cart-inner",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Product Description/pd-1.png",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Product Description/pd-1.png",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Product Description/pd-1.png",
    },
  ];

  const ProductDetails = [
    {
      productimage:
        process.env.PUBLIC_URL + "/assets/Product Description/pd-1.png",
    },
    {
      productimage:
        process.env.PUBLIC_URL + "/assets/Product Description/pd-1.png",
    },
    {
      productimage:
        process.env.PUBLIC_URL + "/assets/Product Description/pd-1.png",
    },
    {
      productimage:
        process.env.PUBLIC_URL + "/assets/Product Description/pd-1.png",
    },
  ];

  const verticalswiperDetails = [
    {
      image: process.env.PUBLIC_URL + "/assets/Product Description/pd-2.png",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Product Description/pd-2.png",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Product Description/pd-2.png",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Product Description/pd-2.png",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Product Description/pd-2.png",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Product Description/pd-2.png",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/Product Description/pd-2.png",
    },
  ];

  const breadcrumbItems = [
    { text: "Home", link: "/" },
    { text: "New Arrivals", link: "/" },
    { text: "Formal Shits", link: "", className:"active" },
  ];

  const colors = ["red", "blue", "green", "yellow"]; // Example colors

  return (
    <>
      {/* B2bSeller Section Started */}

      <section className="B2b-Seller-Detail">
        <div className="Background-image">
          <Container>
            <div className="mb-4">
              <BreadcrumComponent  items={breadcrumbItems} />
            </div>

            <Row className="product-content">
              <Col xxl={5} xl={5} lg={5} md={5}>
                {/* Swiper for products started */}

                <div className="main">
                  <div className="sidebar-swiper">
                    <Swiper
                      direction={"vertical"}
                      // slidesPerView={"auto"}
                      slidesPerView= {4}
                          spaceBetween= {10}
                      modules={[Navigation, Autoplay]}
                      breakpoints={{
                        0: {
                          slidesPerView: 4,
                          spaceBetween: 60,
                        },
                        380: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        485: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        575: {
                          slidesPerView: 4,
                          spaceBetween: 30,
                        },

                        768: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        992: {
                          slidesPerView: 4,
                          spaceBetween: 80,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        1200: {
                          slidesPerView: 4,
                          spaceBetween: 80,
                        },
                        1440: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        2000: {
                          slidesPerView: 4,
                          spaceBetween: 20,
                        },
                      }}
                      navigation
                      pagination={{ clickable: true }}
                      className="mySwiper"
                      onSwiper={(swiper) => setSwiperInstance(swiper)}
                    >
                      {verticalswiperDetails.map((item, index) => (
                        <SwiperSlide>
                          <div className="gallery-imgs">
                            <img
                              src={item.image}
                              className="inner-img"
                              alt="Sofa"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="silder-btn">
                      <div
                        className="back-btn"
                        onClick={() => handleMainSliderClick("prev")}
                      >
                        <FontAwesomeIcon icon={faCaretUp} />
                      </div>
                      <div
                        className="next-btn"
                        onClick={() => handleMainSliderClick("next")}
                      >
                        <FontAwesomeIcon icon={faCaretDown} />
                      </div>
                    </div>
                  </div>

                  {/* this slider for mobile view started*/}
                  <div className="sidebar-swiper-mobile-view">
                    <Swiper
                      slidesPerView={"auto"}
                      modules={[Navigation, Autoplay]}
                      breakpoints={{
                        0: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        320: {
                          slidesPerView: 6,
                          spaceBetween: 10,
                        },
                        485: {
                          slidesPerView: 7,
                          spaceBetween: 10,
                        },
                        575: {
                          slidesPerView: 7,
                          spaceBetween: 20,
                        },

                        768: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                        992: {
                          slidesPerView: 5,
                          spaceBetween: 80,
                        },
                        1024: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                        1200: {
                          slidesPerView: 4,
                          spaceBetween: 80,
                        },
                        1440: {
                          slidesPerView: 5,
                          spaceBetween: 50,
                        },
                        2000: {
                          slidesPerView: 5,
                          spaceBetween: 20,
                        },
                      }}
                      navigation
                      pagination={{ clickable: true }}
                      className="mySwiper"
                      onSwiper={(swiper) => setSwiperInstancemobile(swiper)}
                    >
                      {verticalswiperDetails.map((item, index) => (
                        <SwiperSlide>
                          <div className="gallery-imgs">
                            <img
                              src={item.image}
                              className="inner-img"
                              alt="Sofa"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="silder-btn">
                      <div
                        className="back-btn"
                        onClick={() => handleSliderClickMobile("prev")}
                      >
                        <FontAwesomeIcon icon={faCaretLeft} />
                      </div>
                      <div
                        className="next-btn"
                        onClick={() => handleSliderClickMobile("next")}
                      >
                        <FontAwesomeIcon icon={faCaretRight} />
                      </div>
                    </div>
                  </div>
                  {/* this slider for mobile view End*/}
                  <div className="background-product-detail ">
                    <div className="product-detail">
                      <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        slidesPerView={1}
                        spaceBetween={10}
                        className="mySwiper"
                        onSwiper={(swiper) => setSwiperInstancenew(swiper)}
                        breakpoints={{
                          0: {
                            slidesPerView: 1,
                          },
                          320: {
                            slidesPerView: 1,
                          },
                          485: {
                            slidesPerView: 1,
                          },
                          575: {
                            slidesPerView: 1,
                          },

                          768: {
                            slidesPerView: 1,
                          },
                          992: {
                            slidesPerView: 1,
                          },
                          1024: {
                            slidesPerView: 1,
                          },
                          1200: {
                            slidesPerView: 1,
                          },
                          1440: {
                            slidesPerView: 1,
                          },
                          2000: {
                            slidesPerView: 1,
                          },
                        }}
                      >
                        {imageUrls.map((item, index) => (
                          <SwiperSlide key={index}>
                            <div className="onImageHeartsec">
                              <Link to={item.baseLink}>
                                <img
                                  className="product-img"
                                  src={item.image}
                                  alt="Selected"
                                />
                              </Link>
                              <div
                                className={`Overlay_text_holder ${
                                  showAddedToCart ? "show" : ""
                                }`}
                              >
                                <div className="Overlay_text">
                                  <p>Item added to cart successfully!</p>
                                </div>
                              </div>

                              <div className="productheartIcon">
                                <Heart_Component />
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>

                      <div className="silder-btn">
                        <div
                          className="back-btn"
                          onClick={() => handleSliderClick("prev")}
                        >
                          <FontAwesomeIcon icon={faCaretLeft} />
                        </div>
                        <div
                          className="next-btn"
                          onClick={() => handleSliderClick("next")}
                        >
                          <FontAwesomeIcon icon={faCaretRight} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xxl={7} xl={7} lg={7} md={7}>
                <div className="product-information">
                  <div className="productHead">
                    <div className="heading-share">
                      <div>
                        <h1 className="subheading">
                          Peter England -{" "}
                          <span className="subText">Formal Shirt</span>{" "}
                        </h1>
                      </div>

                      <div className="share-btn">
                        <Link to={"/product-cart-inner"}>
                          <FontAwesomeIcon
                            className="share"
                            icon="fa-solid fa-share"
                          />
                        </Link>
                      </div>
                    </div>

                    <p className="">
                      Men Multi Slim Fit Full Sleeves Printed Shirts
                    </p>
                  </div>

                  <div className="priceSection">
                    <p>₹ 1,234.00</p>
                    <p>₹ 1,234.00</p>
                  </div>

                  <div className="Inclusivetxt">
                    <p>Inclusive of all taxes</p>
                  </div>

                  <div className="colourSection">
                    {colors.map((color, index) => (
                      <div
                        key={index}
                        className={`colorBox ${color}-box`}
                      ></div>
                    ))}
                  </div>

                  <div className="standard-section">
                    <div className="buttons-section">
                      <Button
                        variant="primary"
                        className="gotoCartbtn"
                        onClick={handleAddToCart}
                      >
                        <FontAwesomeIcon icon={faCartShopping} /> Go to Cart
                      </Button>

                      <Button className="gotoCartbtn">
                        <FontAwesomeIcon icon={faLock} /> Buy Now
                      </Button>
                    </div>
                  </div>

                  <div className="sizeSection">
                    <div className="sizeNumber button_slide slide_down active">
                      38
                    </div>
                    <div className="sizeNumber button_slide slide_down">39</div>
                    <div className="sizeNumber button_slide slide_down">40</div>
                    <div className="sizeNumber button_slide slide_down">41</div>
                    <div className="sizeNumber button_slide slide_down">42</div>
                    <div className="sizeNumber button_slide slide_down">43</div>
                    <div className="sizeNumber button_slide slide_down">44</div>
                  </div>

                  <div>
                    <div className="accordionHead">
                      <h3>PRODUCT DESCRIPTION</h3>
                      <p>
                        Update your look with this Multi Print Slim Fit shirt
                        from Peter England and enjoy the attention.
                      </p>
                    </div>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          PRODUCT DETAILS<span></span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="accordionContent">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-6">
                                <div className="contentHead">
                                  <h3>StyleCode:</h3>
                                  <p>ASSFCUSPFF85741</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Sleeves:</h3>
                                  <p>Full Sleeves</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Color:</h3>
                                  <p>Multi</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Brand:</h3>
                                  <p>Peter England</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Product Type:</h3>
                                  <p>Shirt</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Fit:</h3>
                                  <p>Shirt</p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-6">
                                <div className="contentHead">
                                  <h3>Pattern:</h3>
                                  <p>Slim</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Occasion:</h3>
                                  <p>Formal</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Cuffs:</h3>
                                  <p>Regular Cuffs</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Sub Brand:</h3>
                                  <p>Peter England - Multi Slim Fit</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Material:</h3>
                                  <p>Cotton</p>
                                </div>

                                <div className="contentHead">
                                  <h3>Color:</h3>
                                  <p>Regular Collar</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          PRODUCT DECLARATION <span></span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <h4 className="product">
                            Product Highlight: Luxurious Comfort and Timeless
                            Elegance with Our Sofa
                          </h4>
                          <p className="product-text">
                            Indulge in the epitome of comfort and style with our
                            exquisite sofa, meticulously crafted to elevate your
                            living space to new heights of sophistication. Here
                            are the key highlights of our sofa:
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          DELIVERY & RETURNS <span></span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <h4 className="product">
                            Product Highlight: Luxurious Comfort and Timeless
                            Elegance with Our Sofa
                          </h4>
                          <p className="product-text">
                            Indulge in the epitome of comfort and style with our
                            exquisite sofa, meticulously crafted to elevate your
                            living space to new heights of sophistication. Here
                            are the key highlights of our sofa:
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default ProductCart;
