import React from 'react'
import './AboutBanner.css'
const AboutBanner = () => {
    return (
        <>
            <section className='about-banner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 mx-auto mt-3'>
                            <img src={process.env.PUBLIC_URL + "/assets/about/about-banner.png"} className="about-banner" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutBanner