import React from "react";
import "./Style_On.css";
import Select_Drop from "../../Common_Component/Select_Drop/Select_Drop";
import Buttons from "../../Common_Component/Buttons/Buttons";

function Style_On() {
  // Define options for each Select_Drop component
  const shoppingForOptions = [
    { value: "1", label: "MEN" },
    { value: "2", label: "FEMALE" },
    { value: "3", label: "KIDS" },
  ];

  const goingToOptions = [
    { value: "1", label: "WEDDING" },
    { value: "2", label: "OFFICE / MEETING" },
    { value: "3", label: "SPORTS / OUTING" },
    { value: "4", label: "CASUAL OUTING" },
    { value: "5", label: "PARTY" },
  ];

  const preferOptions = [
    { value: "1", label: "ETHNIC" },
    { value: "2", label: "CEREMONIAL" },
    { value: "3", label: "CASUAL" },
    { value: "4", label: "PARTY" },
  ];

  return (
    <>
      <div className="Style_On">
        <div className="container">
          <div className="Style_On_Drop_Holder">
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="select_drop_holder">
                  <Select_Drop
                    labelText="SHOPPING FOR"
                    optionsSelect={shoppingForOptions}
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="select_drop_holder">
                  <Select_Drop
                    labelText="GOING TO"
                    optionsSelect={goingToOptions}
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="select_drop_holder">
                  <Select_Drop
                    labelText="PREFER"
                    optionsSelect={preferOptions}
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="select_drop_holder">
                  <div className="style-btn-holder  ">
                    <Buttons className={"style-btn"} text="STYLE ON" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Style_On;
