import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Assuming you're using React Router

import "./Heart_Component.css";

function Heart_Component() {
  const [heartactive, setheartActive] = useState(false);
  const handleClickes = () => {
    setheartActive(!heartactive);
  };

  const location = useLocation();

  useEffect(() => {
    // Check if the current location matches "/wishlist"
    if (location.pathname === "/wishlist") {
      setheartActive(true); // Set the initial state to true if the URL is "/wishlist"
    }
  }, [location.pathname]); // Run this effect whenever the location pathname changes

 
  return (
    <>
      <div className="hearticn">
        <FontAwesomeIcon
          icon={faHeart}
          onClick={handleClickes}
          className={heartactive ? "gobiheartss" : "gobiheart"}
        />
      </div>
    </>
  );
}

export default Heart_Component;
