import React from "react";
import "./Home_Banner.css";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

function Home_Banner() {
  // Define an array of slide data
  const slideData = [
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/B2C_Home_Banner/Banner_main_img.png",
      imgClassName: "banner_main_img",
      detailsLink:"/our_products_page",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/B2C_Home_Banner/Banner_main_img.png",
      imgClassName: "banner_main_img",
      detailsLink:"/our_products_page",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/B2C_Home_Banner/Banner_main_img.png",
      imgClassName: "banner_main_img",
      detailsLink:"/our_products_page",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/B2C_Home_Banner/Banner_main_img.png",
      imgClassName: "banner_main_img",
      detailsLink:"/our_products_page",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/B2C_Home_Banner/Banner_main_img.png",
      imgClassName: "banner_main_img",
      detailsLink:"/our_products_page",
    },
    {
      imgSource:
        process.env.PUBLIC_URL +
        "/assets/Home/B2C_Home_Banner/Banner_main_img.png",
      imgClassName: "banner_main_img",
      detailsLink:"/our_products_page",
    },
  ];
  return (
    <>
      <div className="Home_Banner">
        <div className="container">
          <div className="Home_Banner_Card_Holder">
            <div className="row ms-0 ms-0">
              <div className="col-lg-6 col-xl-7 col-md-7">
                <div className="Home_Banner_Card_Slider_Holder">
                  <Swiper
                    className="mySwiper"
                    spaceBetween={1}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                  >
                    {slideData.map((slide, index) => (
                      <SwiperSlide key={index}>
                        <Banner_Card
                          imgClassName={slide.imgClassName}
                          imgSource={slide.imgSource}
                          detailsLink={slide.detailsLink}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>

              <div className="col-lg-6 col-xl-5 col-md-5">
                <div className="row">
                  <div className="col-md-6 col-6">
                    <div className="Home_Banner_Small_Card">
                      <div className="row">
                        <div className="col-md-12">
                          <Link to={"/our_products_page"}>
                            <div className="smallcardban">
                              <Banner_Card
                                className="small_card"
                                imgClassName="Home_Banner_Small_Card_img"
                                imgSource={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/B2C_Home_Banner/Banner_Card_img_1.png"
                                }
                              />
                              <div className="overlayss"></div>
                            </div>
                          </Link>
                        </div>
                        <div className="col-md-12">
                          <Link to={"/our_products_page"}>
                            <div className="smallcardban">
                              <Banner_Card
                                className="smallcard2"
                                imgClassName="Home_Banner_Small_Card_img"
                                imgSource={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/B2C_Home_Banner/Banner_Card_img_1.png"
                                }
                              />
                              <div className="overlayss mx-auto"></div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-6">
                    <Link to={"/our_products_page"}>
                      <div className="Home_Banner_Long_Card">
                        <div className="longcardban">
                          <Banner_Card
                            imgClassName="Home_Banner_Long_Card_img"
                            imgSource={
                              process.env.PUBLIC_URL +
                              "/assets/Home/B2C_Home_Banner/Banner_Card_img_long.png"
                            }
                          />
                          <div className="overlayss mx-auto"></div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home_Banner;
