import React, { useState } from "react";
import "./Our_Products_Page.css";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import Product_Swiper from "../Common_Component/Product_Swiper/Product_Swiper";
import Banner_Card from "../Common_Component/Banner_Card/Banner_Card";
import Heart_Component from "../Common_Component/Heart_Component/Heart_Component";
import Color_Cart_Holder from "../Common_Component/Color_Cart_Holder/Color_Cart_Holder";
import Product_Details_Div from "../Common_Component/Product_Details_Div/Product_Details_Div";
import { Form, Nav, Tab } from "react-bootstrap";
import Buttons from "../Common_Component/Buttons/Buttons";
import { Link } from "react-router-dom";
import BreadcrumComponent from "../Common_Component/BreadcrumComponent/BreadcrumComponent";
import Pagenation from "../Common_Component/Pagenation/Pagenation";

const sliderMenImages = [
  {
    imgSource:
      process.env.PUBLIC_URL +
      "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
    detailsLink: "/productdescription",
    subheading: "Peter England -",
    spanText: " Formal Shirt",
    productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
    Price: "₹ 1,234.00",
    subPrice: "₹ 1,234.00",
  },

  {
    imgSource:
      process.env.PUBLIC_URL +
      "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
    detailsLink: "/productdescription",
    subheading: "Allen Solly -",
    spanText: "Semi Formal Shirt",
    productDescription: "Men Blue Solid Casual Jacket",
    Price: "₹ 1,234.00",
    subPrice: "₹ 1,234.00",
  },

  {
    imgSource:
      process.env.PUBLIC_URL +
      "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
    detailsLink: "/productdescription",
    subheading: "Van Heusen - ",
    spanText: "Semi Formal Shirt",
    productDescription: "Men Multi Slim Fit Full Sleeves Formal Shirts",
    Price: "₹ 1,234.00",
    subPrice: "₹ 1,234.00",
  },

  {
    imgSource:
      process.env.PUBLIC_URL +
      "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
    detailsLink: "/productdescription",
    subheading: "Raymond -",
    spanText: " Formal Shirt",
    productDescription: "Men Slim Fit Shirt",
    Price: "₹ 1,234.00",
    subPrice: "₹ 1,234.00",
  },

  {
    imgSource:
      process.env.PUBLIC_URL +
      "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
    detailsLink: "/productdescription",
    subheading: "Raymond -",
    spanText: " Formal Shirt",
    productDescription: "Men Slim Fit Full Sleeves Formal Shirts",
    Price: "₹ 1,234.00",
    subPrice: "₹ 1,234.00",
  },

  {
    imgSource:
      process.env.PUBLIC_URL +
      "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
    detailsLink: "/productdescription",
    subheading: "Peter England -",
    spanText: " Formal Shirt",
    productDescription: "Men Multi Slim Fit Full Sleeves Printed Shirts",
    Price: "₹ 1,234.00",
    subPrice: "₹ 1,234.00",
  },

  {
    imgSource:
      process.env.PUBLIC_URL +
      "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
    detailsLink: "/productdescription",
    subheading: "Raymond -",
    spanText: " Formal Shirt",
    productDescription: "Men Slim Fit Shirt",
    Price: "₹ 1,234.00",
    subPrice: "₹ 1,234.00",
  },

  {
    imgSource:
      process.env.PUBLIC_URL +
      "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
    detailsLink: "/productdescription",
    subheading: "Raymond -",
    spanText: " Formal Shirt",
    productDescription: "Men Slim Fit Full Sleeves Formal Shirts",
    Price: "₹ 1,234.00",
    subPrice: "₹ 1,234.00",
  },

];

const popularProducts = [
  {
    imgSource: "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_1.png",
    detailsLink: "/productdescription",
    subheading: "Peter England - ",
    spanText: "Formal Shirt ",
    price: "₹ 1,234.00 ",
    subPrice: "₹ 1,234.00 ",
  },
  {
    imgSource: "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_2.png",
    detailsLink: "/productdescription",
    subheading: "Peter England - ",
    spanText: "Formal Shirt ",
    price: "₹ 1,234.00 ",
    subPrice: "₹ 1,234.00 ",
  },
  {
    imgSource: "/assets/Home/New_Arrivel/New_Arrivel_Slider_Img_3.png",
    detailsLink: "/productdescription",
    subheading: "Peter England - ",
    spanText: "Formal Shirt ",
    price: "₹ 1,234.00 ",
    subPrice: "₹ 1,234.00 ",
  },
];

const renderCategoryTabs = () => {
  const categories = [
    {
      labelText: "Men",
      id: "1",
    },

    {
      labelText: "Women",
      id: "2",
    },

    {
      labelText: "Kids",
      id: "3",
    },
  ];

  return categories.map((category, index) => (
    <Nav.Item key={index}>
      <div className=" option_text" eventKey={index + 1}>
        <Form>
          <Form.Check
            type="checkbox"
            id={category.id}
            label={category.labelText}
          />
        </Form>
      </div>
    </Nav.Item>
  ));
};

const renderSizeTabs = () => {
  const categoriesSize = [
    {
      labelText: "Small",
      id: "1",
    },

    {
      labelText: "Medium",
      id: "2",
    },

    {
      labelText: "Large",
      id: "3",
    },

    {
      labelText: "Extra Large",
      id: "4",
    },
  ];

  return categoriesSize.map((categorySizeOption, indexSize) => (
    <div key={indexSize}>
      <div className=" nav-link option_text" eventKey={indexSize + 1}>
        <Form>
          <Form.Check
            type="checkbox"
            id={categorySizeOption.id}
            label={categorySizeOption.labelText}
          />
        </Form>
      </div>
    </div>
  ));
};

const renderPopularProducts = () => {
  return popularProducts.map((product, index) => (
    <div key={index} className="Popular_Product_holder">
      <div className="row">
        <div className="col-xl-4 col-md-12 col-sm-12 ">
          <Link to={product.detailsLink}>
            <div className="img-holder">
              <img
                className="Popular_Product_img"
                src={process.env.PUBLIC_URL + product.imgSource}
                alt={`Popular_Product_img-${index}`}
              />
            </div>
          </Link>
        </div>
        <div className=" col-xl-8 col-md-12  col-sm-12">
          <div className="product_info_holder">
            <h1 className="subheading">
              {product.subheading}{" "}
              <span className="spanText">{product.spanText}</span>
            </h1>
            <div>
              <span className="spanText ">{product.price}</span>
              <span className="label_text subPrice">{product.subPrice}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));
};

const renderPopularProductsTabs = () => {
  return <div className="Popular_Product">{renderPopularProducts()}</div>;
};

const breadcrumbItems = [
  { text: "Home", link: "/" },
  { text: "All Products", className: "active" },
];
function Our_Products_Page() {
  return (
    <>
      <div className="Our_Products_Page Category ">
        <div className="container">
          <div className="mb-4">
            <BreadcrumComponent items={breadcrumbItems} />
          </div>
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-md-3 col-md-3 col-sm-3 col-5 ">
                <Nav variant="pills" className="categoryTabs">
                  <div className="filter-holder">
                    <div className="Categories-holder">
                      <div className="heading-holder">
                        <h1 className="heading">Categories</h1>
                        <div className="title-border"></div>
                      </div>

                      {renderCategoryTabs()}
                    </div>

                    <div className="Categories-holder">
                      <div className="heading-holder">
                        <h1 className="heading">Filter by Price</h1>
                        <div className="title-border"></div>
                      </div>

                      <div className="rangebar_holder">
                        <RangeSlider />
                      </div>

                      <div className="filter_price_text">
                        <div>
                          <span className="spanText me-1">Price:</span>
                          <span className="label_text subPrice">
                            $200 - $550
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="Categories-holder">
                      <div className="heading-holder">
                        <h1 className="heading">Popular Product</h1>
                        <div className="title-border"></div>
                      </div>

                      {renderPopularProductsTabs()}
                    </div>

                    <div className="Categories-holder">
                      <div className="heading-holder">
                        <h1 className="heading">Sizes</h1>
                        <div className="title-border"></div>
                      </div>

                      {renderSizeTabs()}
                    </div>
                  </div>
                </Nav>
              </div>
              <div className="col-xxl-9 col-xl-9 col-md-9 col-lg-9 col-sm-9 col-7">
          
                    <div className="tab-content" >
                      <div className="Our_Products_Page_Card">
                        <div className="row">
                          {sliderMenImages.map((product, index) => (
                            <div
                              key={index}
                              className="col-xxl-4 col-xl-4 col-md-4 col-sm-6"
                            >
                              <Banner_Card
                                imgClassName="Tab_Slider_Img"
                                detailsLink={product.detailsLink}
                                imgSource={product.imgSource}
                                heartClassName={"Heart_Like"}
                                HeartLikeComponent={<Heart_Component />}
                                ProductDetailsClassName={"Product_Details"}
                                ProductDetailsSection={
                                  <Product_Details_Div
                                    ColorCartHolder={<Color_Cart_Holder />}
                                    subheading={product.subheading}
                                    spanText={product.spanText}
                                    productDescription={
                                      product.productDescription
                                    }
                                    Price={product.Price}
                                    subPrice={product.subPrice}
                                  />
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                <Pagenation />
              </div>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
}

export default Our_Products_Page;
