import React from "react";
import "./Header.css";
import { Col, Container, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faShoppingCart, faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Form from "react-bootstrap/Form";
const Header = () => {
  return (
    <>
      <section className="header-section">
        <Container fluid className="p-0">
          <Container className="p-0">
            <div className="header-main roboto-family">
              <Navbar collapseOnSelect expand="lg" className=" p-0 m-0">
                <Navbar.Brand className="header-img">
                  <Link to={"/"}>
                    <img
                      className="header-logo"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Websitelogo/netpurti_logo.png"
                      }
                      alt="Logo"
                    />
                  </Link>
                </Navbar.Brand>

                <div className="mobile_view_icon_holder">
                  <Nav className="navbMainTwo ">
                    <div className="xs-view">
                      <div className="mobile_view_icon ">
                        <Nav.Link
                          as={Link}
                          to="/product-cart-inner"
                          className="header-lists2 "
                        >
                          <FontAwesomeIcon icon={faShoppingCart} />
                        </Nav.Link>
                        <Nav.Link
                          as={Link}
                          to="/wishlist"
                          className="header-lists2 "
                        >
                          <FontAwesomeIcon icon={farHeart} />
                        </Nav.Link>

                        <Nav.Link
                          as={Link}
                          to="/login"
                          className="header-lists2 "
                        >
                          <FontAwesomeIcon icon={faUser} />
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav>
                </div>
                <div className="mobile_view_icon_holder">
                  <Nav className="navbMainTwo ">
                    <Nav.Link className="header-lists2 ">
                      <div className="SearchinputMain">
                        <Form.Control type="email" placeholder="Search here" />
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="searchicon"
                        />
                      </div>
                    </Nav.Link>
                    <div className="lg_view">
                      <div className="mobile_view_icon ">
                        <Nav.Link
                          as={Link}
                          to="/product-cart-inner"
                          className="header-lists2 "
                        >
                          <FontAwesomeIcon icon={faShoppingCart} />
                        </Nav.Link>
                        <Nav.Link
                          as={Link}
                          to="/wishlist"
                          className="header-lists2 "
                        >
                          <FontAwesomeIcon icon={farHeart} />
                        </Nav.Link>

                        <Nav.Link
                          as={Link}
                          to="/login"
                          className="header-lists2 "
                        >
                          <FontAwesomeIcon icon={faUser} />
                        </Nav.Link>
                      </div>
                    </div>
                  </Nav>
                </div>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="me-auto navbMainOne">
                    <Link to={"/about-us"}>
                      <Nav.Link href="/about-us" className="header-lists">
                        About Us
                      </Nav.Link>
                    </Link>
                    <Link to={"/contact-us"}>
                      <Nav.Link href="/contact-us" className="header-lists">
                        Contact Us
                      </Nav.Link>
                    </Link>
                    <Link to={"/blog"}>
                      <Nav.Link href="/blog" className="header-lists">
                        Blog
                      </Nav.Link>
                    </Link>
                    <Link to={"/faq"}>
                      <Nav.Link href="/faq" className="header-lists">
                        FAQ
                      </Nav.Link>
                    </Link>
                  </Nav>

                  {/* <Nav className="navbMainTwo">
                    <Nav.Link className="header-lists2 ">
                      <div className="SearchinputMain">
                        <Form.Control type="email" placeholder="Search here" />
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="searchicon"
                        />
                      </div>
                    </Nav.Link>

                    <Nav.Link as={Link} to="/product-cart-inner" className="header-lists2 ">
                      <FontAwesomeIcon icon={faShoppingCart} />
                    </Nav.Link>
                    <Nav.Link  as={Link} to="/productdescription" className="header-lists2 ">
                      <FontAwesomeIcon icon={farHeart} />
                    </Nav.Link>

                    <Nav.Link as={Link} to="/login" className="header-lists2 ">
                      <FontAwesomeIcon icon={faUser} />
                    </Nav.Link>
                  </Nav> */}

                  <Nav className="navbMainTwo desktop_view_icon_holder">
                    <Nav.Link className="header-lists2 ">
                      <div className="SearchinputMain">
                        <Form.Control type="email" placeholder="Search here" />
                        <FontAwesomeIcon
                          icon={faSearch}
                          className="searchicon"
                        />
                      </div>
                    </Nav.Link>
                    <div className="mobile_view_icon">
                      <Nav.Link
                        as={Link}
                        to="/product-cart-inner"
                        className="header-lists2 "
                      >
                        <FontAwesomeIcon icon={faShoppingCart} />
                      </Nav.Link>
                      <Nav.Link
                        as={Link}
                        to="/wishlist"
                        className="header-lists2 "
                      >
                        <FontAwesomeIcon icon={farHeart} />
                      </Nav.Link>

                      <Nav.Link
                        as={Link}
                        to="/login"
                        className="header-lists2 "
                      >
                        <FontAwesomeIcon icon={faUser} />
                      </Nav.Link>
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </Container>
        </Container>
      </section>
    </>
  );
};

export default Header;
