import React from 'react'
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import ScrollToTop from './Component/ScrollTotop/ScrollTotop'
import Header from './Component/Navbar/Header/Header';
import "./Component/Common_Css/Text_Css/Text_Css.css"
import "./Component/Common_Css/Swiper_Slider_Css/Swiper_Slider_Css.css"
import Footer from './Component/Footer/Footer';
import "./App.css"
import AllRoutes from './Component/AllRoutes/AllRoutes';

function App() {
  const location = useLocation();
  const noHeaderFooterRoutes = ['/login', '/register', '/resetpassword', '/setnewpassword'];

  const shouldHideHeaderFooter = noHeaderFooterRoutes.includes(location.pathname);
  return (
    <>
      <ScrollToTop />

     


      {!shouldHideHeaderFooter && <Header />}
      <AllRoutes />
      {!shouldHideHeaderFooter && <Footer />}



    </>
  )
}

export default App