import React from "react";
import Heading_Holder from "../../Common_Component/Heading_Holder/Heading_Holder";
import "./Shop_Trends.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Nav_Pills_Holder from "../../Common_Component/Nav_Pills_Holder/Nav_Pills_Holder";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";
import { Link } from "react-router-dom";

function Shop_Trends() {
  const ShopTrendsPiils = [
    { eventKey: "Men", title: "Men" },
    { eventKey: "Women", title: "Women" },
    { eventKey: "Kids", title: "Kids" },
  ];

  return (
    <>
      <div className="Shop_Trends">
        <div className="container">
          <Heading_Holder headingText={"SHOP TRENDS"} />

          <div className="Shop_Trends_Cards">
            <Tab.Container id="left-tabs-example" defaultActiveKey="Men">
              <div className="row">
                <div className="col-md-12">
                  <Nav_Pills_Holder tabs={ShopTrendsPiils} />
                </div>
                <div className="tab_content_holder">
                  <Tab.Content>
                    <Tab.Pane eventKey="Men">
                      <div className="Men_Tab_Content">
                        <div className="row">
                          <div className="col-md-6 col-6">
                            <div className="Men_Tab_Content_img_holder shoptrendimg">
                              <Banner_Card
                                imgClassName="Women_Tab_Content_img shoptrenss"
                                imgSource={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/Shop_Trends/Mens/Shop_Trends_Men_img_2.png"
                                }
                                detailsLink="/our_products_page"
                                overlayClassName={"overlay"}
                                overlayText={"Best Seller"}
                                background={"#1D44AD"}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-6">
                            <div className="Men_Tab_Content_img_holder shoptrendimg">
                              <Banner_Card
                                imgClassName="Women_Tab_Content_img shoptrenss"
                                imgSource={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/Shop_Trends/Mens/Shop_Trends_Men_img_1.png"
                                }
                                detailsLink="/our_products_page"
                                overlayClassName={"overlay"}
                                overlayText={"Trendy"}
                                background={"#1D44AD"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="Women">
                      <div className="Women_Tab_Content">
                        <div className="row">
                          <div className="col-md-6 col-6">
                            <div className="Men_Tab_Content_img_holder shoptrendimg">
                              <Banner_Card
                                imgClassName="Women_Tab_Content_img shoptrenss"
                                imgSource={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/Shop_Trends/Mens/Shop_Trends_Men_img_2.png"
                                }
                                detailsLink="/our_products_page"
                                overlayClassName={"overlay"}
                                overlayText={"Best Seller"}
                                background={"#1D44AD"}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-6">
                            <div className="Men_Tab_Content_img_holder shoptrendimg">
                              <Banner_Card
                                imgClassName="Women_Tab_Content_img shoptrenss"
                                imgSource={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/Shop_Trends/Mens/Shop_Trends_Men_img_1.png"
                                }
                                detailsLink="/our_products_page"
                                overlayClassName={"overlay"}
                                overlayText={"Trendy"}
                                background={"#1D44AD"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="Kids">
                      <div className="Women_Tab_Content">
                        <div className="row">
                          <div className="col-md-6 col-6">
                            <div className="Men_Tab_Content_img_holder shoptrendimg">
                              <Banner_Card
                                imgClassName="Women_Tab_Content_img shoptrenss"
                                imgSource={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/Shop_Trends/Mens/Shop_Trends_Men_img_2.png"
                                }
                                detailsLink="/our_products_page"
                                overlayClassName={"overlay"}
                                overlayText={"Best Seller"}
                                background={"#1D44AD"}
                              />
                            </div>
                          </div>

                          <div className="col-md-6 col-6">
                            <div className="Men_Tab_Content_img_holder shoptrendimg">
                              <Banner_Card
                                imgClassName="Women_Tab_Content_img shoptrenss"
                                imgSource={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/Shop_Trends/Mens/Shop_Trends_Men_img_1.png"
                                }
                                detailsLink="/our_products_page"
                                overlayClassName={"overlay"}
                                overlayText={"Trendy"}
                                background={"#1D44AD"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default Shop_Trends;
