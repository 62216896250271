import React from "react";
import Home_Banner from "./Home_Banner/Home_Banner";
import Category from "./Category/Category";
import Style_On from "./Style_On/Style_On";
import Explore_Brands from "./Explore_Brands/Explore_Brands";
import ExclusiveCollection from "./ExclusiveCollection/ExclusiveCollection";
import Crazy_Deals from "./Crazy_Deals/Crazy_Deals";
import Shop_Trends from "./Shop_Trends/Shop_Trends";
import New_Arrivels from "./New_Arrivels/New_Arrivels";
import Color_Cart_Holder from "../Common_Component/Color_Cart_Holder/Color_Cart_Holder";

function HomePage() {
  return (
    <>
      <Home_Banner />
      <Style_On />
      <Category />
      <Explore_Brands />
      <Crazy_Deals />
      <ExclusiveCollection />
      <Shop_Trends />
      <New_Arrivels />

    </>
  );
}

export default HomePage;
