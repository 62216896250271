import React from 'react'
import { Route, Routes } from "react-router-dom";
import HomePage from '../HomePage/HomePage';
import Login from '../Login/Login';
import Register from '../Login/Register/Register';
import ResetPassWord from '../Login/ResetPassWord/ResetPassWord';
import SetNewPassword from '../Login/SetNewPassword/SetNewPassword';
import CheckEmail from '../Login/CheckEmail/CheckEmail';
import ProductDescription from '../ProductDescription/ProductDescription';
import Payment_checkout from '../payment-checkout-form/Payment_checkout';
import Product_Cart_Inner from '../Product_Cart_Inner/Product_Cart_Inner';
import AboutUs from '../About-us/AboutUs';
import ContactUs from '../contact-us/ContactUs';
import Blog from '../Blog/Blog';
import Faq from '../Faq/Faq';
import BlogDetails from '../Blog/Blog-details/BlogDetails';
import Our_Products_Page from "../Our_Products_Page/Our_Products_Page.js"
import WishList from '../WishList/WishList.js';

const AllRoutes = () => {
    return (
        <>
            <Routes>
                <Route path='/' element={<HomePage />}></Route>
                <Route path='/login' element={<Login />}></Route>
                <Route path='/register' element={<Register />}></Route>
                <Route path='/resetpassword' element={<ResetPassWord />}></Route>
                <Route path='/setnewpassword' element={<SetNewPassword />}></Route>
                <Route path='/checkemail' element={<CheckEmail />}></Route>
                <Route path='/productdescription' element={<ProductDescription />}></Route>
                <Route path='/checkout-form' element={<Payment_checkout />}></Route>
                <Route path='/product-cart-inner' element={<Product_Cart_Inner/>}></Route>
                <Route path='/our_products_page' element={<Our_Products_Page/>}></Route>
                <Route path='/wishlist' element={<WishList/>}></Route>
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/blog-details" element={<BlogDetails />} />
                <Route path="/faq" element={<Faq />} />


            </Routes>

        </>
    )
}

export default AllRoutes